import { create } from 'zustand'

import {
  CenterData,
  Participant,
  RecruitmentStudy,
  RecruitmentVariable,
  SorterOrder,
  TableColumnVariableSource,
  TableVariable
} from '../../../requests'
import { TableRecord } from '../../../utils'

interface ParticipantsTableOptions {
  pageNumber: number
  search: string
  filters?: Record<string, string>
  sorter: {
    field: keyof Participant
    order: SorterOrder
  }
}

export interface AllRecruitmentVariables {
  [TableColumnVariableSource.SubjectDatabase]: TableVariable[]
  [TableColumnVariableSource.RecruitmentSurvey]: RecruitmentVariable[]
}

export type ShowFiltersValue = 'search' | 'filter'

interface RecruitmentStudyDetailsState {
  study: RecruitmentStudy
  reloadParticipantsTable: boolean
  reloadKpis: boolean
  participantsTableOptions: ParticipantsTableOptions
  centers: CenterData[]
  setStudy: (study: RecruitmentStudy) => void
  triggerReloadParticipantsTable: () => void
  triggerReloadKpis: () => void
  setParticipantsTableOptions: (options: ParticipantsTableOptions) => void
  setCenters: (centers: CenterData[]) => void
  participants: TableRecord<Participant>[]
  setParticipants: (participants: TableRecord<Participant>[]) => void
  participantToView: Participant
  setParticipantToView: (participant: Participant) => void
  updateParticipant: (participant: Participant) => void
  filters: Record<string, string[]>
  setFilters: (filters: Record<string, string[]>) => void
  clearStudyDetails: () => void
  allVariables: AllRecruitmentVariables
  setRecruitmentVariables: (variables: AllRecruitmentVariables[TableColumnVariableSource.RecruitmentSurvey]) => void
  setSubjectVariables: (variables: AllRecruitmentVariables[TableColumnVariableSource.SubjectDatabase]) => void
  showFilters: ShowFiltersValue
  toggleShowFilters: (val: ShowFiltersValue) => void
}

const studyDetailsInitialState = {
  study: null as RecruitmentStudy,
  centers: [] as CenterData[],
  participants: [] as TableRecord<Participant>[],
  participantToView: null as Participant,
  filters: null as Record<string, string[]>,
  participantsTableOptions: {
    pageNumber: 0,
    search: '',
    sorter: {
      field: 'firstName' as keyof Participant,
      order: SorterOrder.Ascend
    }
  },
  allVariables: {
    [TableColumnVariableSource.SubjectDatabase]: [] as TableVariable[],
    [TableColumnVariableSource.RecruitmentSurvey]: [] as RecruitmentVariable[]
  },
  showFilters: null as ShowFiltersValue
}

export const useRecruitmentStudyDetailsStore = create<RecruitmentStudyDetailsState>()(set => ({
  ...studyDetailsInitialState,
  setStudy: (study: RecruitmentStudy) => set({ study }),
  reloadParticipantsTable: false,
  reloadKpis: false,
  triggerReloadParticipantsTable: () =>
    set((state: RecruitmentStudyDetailsState) => ({
      reloadParticipantsTable: !state.reloadParticipantsTable,
      reloadKpis: !state.reloadKpis
    })),
  triggerReloadKpis: () =>
    set((state: RecruitmentStudyDetailsState) => ({
      reloadKpis: !state.reloadKpis
    })),
  setParticipantsTableOptions: (participantsTableOptions: ParticipantsTableOptions) =>
    set({ participantsTableOptions }),
  setCenters: (centers: CenterData[]) => set({ centers }),
  setParticipants: (participants: TableRecord<Participant>[]) => set({ participants }),
  setParticipantToView: (participant: Participant) => set({ participantToView: participant }),
  updateParticipant: (participant: Participant) =>
    set((state: RecruitmentStudyDetailsState) => {
      const updatedParticipants = [...state.participants]
      const participantIndex = updatedParticipants.findIndex(c => c.id === participant.id)
      updatedParticipants[participantIndex] = { ...participant, key: participant.id }
      return { participants: updatedParticipants }
    }),
  setFilters: (filters: Record<string, string[]>) => set({ filters }),
  clearStudyDetails: () => set(studyDetailsInitialState),
  setRecruitmentVariables: (variables: AllRecruitmentVariables[TableColumnVariableSource.RecruitmentSurvey]) =>
    set((state: RecruitmentStudyDetailsState) => ({
      allVariables: { ...state.allVariables, [TableColumnVariableSource.RecruitmentSurvey]: variables }
    })),
  setSubjectVariables: (variables: AllRecruitmentVariables[TableColumnVariableSource.SubjectDatabase]) =>
    set((state: RecruitmentStudyDetailsState) => ({
      allVariables: { ...state.allVariables, [TableColumnVariableSource.SubjectDatabase]: variables }
    })),
  toggleShowFilters: (val: ShowFiltersValue) =>
    set((state: RecruitmentStudyDetailsState) => ({
      showFilters: state.showFilters === val ? null : val
    }))
}))
